import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import NextArrow from "../assets/svg/next_arrow.svg";
import HappySpinner from "./HappySpinner";
import { GlobalStyle, globalColors } from "../assets/GlobalStyles";


export default function Nav({ url }) {
  //url prop is the current page url

  const [nextURL, setNextURL] = useState("");

  let paths = ["T", "R", "I", "S", "H", "about"];

  //I trimmed the url prop because you get an external link error if you pass the whole url
  let trimmedURL = url.slice(1, url.length - 1);

  useEffect(() => {
    function getNextURL(url, paths) {
      if (url === "about") {
        setNextURL("T");
      } else {
        let i = paths.findIndex((path) => path === url);
        if (url === paths[i]) {
        }
        let nextURL = paths[i + 1];
        setNextURL(nextURL);
      }
    }

    getNextURL(trimmedURL, paths);
  });

  return (
    <>
    <GlobalStyle />
      <NavWrapper>
      
        <StyledSpinnerWrapper>
          <HappySpinner location="/" />
        </StyledSpinnerWrapper>

        <StyledLetter
          to="/T"
          activeStyle={{
            color: `${globalColors.brandWhite}`,
          }}
        >
          T
        </StyledLetter>
        <StyledLetter
          to="/R"
          activeStyle={{
            color: `${globalColors.brandWhite}`,
          }}
        >
          R
        </StyledLetter>
        <StyledLetter
          to="/I"
          activeStyle={{
            color: `${globalColors.brandWhite}`,
          }}
        >
          I
        </StyledLetter>
        <StyledLetter
          to="/S"
          activeStyle={{
            color: `${globalColors.brandWhite}`,
          }}
        >
          S
        </StyledLetter>
        <StyledLetter
          to="/H"
          activeStyle={{
            color: `${globalColors.brandWhite}`,
          }}
        >
          H
        </StyledLetter>

        <Link to={`/${nextURL}`}>
          <StyledNextSVG />
        </Link>
      </NavWrapper>
    </>
  );
}

const NavWrapper = styled.div`

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2em;
  margin: 2em;
  margin-bottom: -1.5em;
  @media (max-width: 920px) {
    margin: auto;
  }
`;

const StyledNextSVG = styled(NextArrow)`
  width: 3em;
  margin-left: 1em;
  fill: ${globalColors.arrowColor};
  transition-duration: 0.3s;
  
  &:hover {
    fill: ${globalColors.brandWhite};
  }

  @media (max-width: 920px) {

    pointer-events: none;
    fill: ${globalColors.arrowColor};
    
  }
`;

const StyledLetter = styled(Link)`
  font-family: program, sans-serif;

font-weight: 900;

font-style: normal;

  display: block-inline;
  color: ${globalColors.nameColor};
  font-size: 5em;
  letter-spacing: 0.02em;
`;

const StyledSpinnerWrapper = styled.div`
  width: 7em;
  @media (max-width: 920px) {
    min-width: 5em;
  }
`;
