import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import styled, { keyframes } from "styled-components";
import { Link } from "gatsby";
import flower from "../assets/images/flower.png";

const HappySpinner = ({ location }) => {
  return (
    <>
      <StyledLink to={location}>
        
          <StyledSpinner>
            <StyledImage src={flower} alt="spinner" />
          </StyledSpinner>
        
      </StyledLink>
    </>
  );
};

export default HappySpinner;

const StyledLink = styled(Link)`
  text-decoration: none;
  background-color: transparent;
`;


const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const StyledSpinner = styled.div`
  /* width: 5em;
  height: 100%; */

  margin: 0 1em auto auto;

  animation: ${spin} 10s linear infinite;
`;

const StyledImage = styled.img`
  width: 100%;
`;
