import React from "react";
import { GlobalStyle } from "../assets/GlobalStyles";
import Nav from "../components/Nav";

const PhotoPageLayout = ({ children, url }) => {
  return (
    <>
      <GlobalStyle />
      <Nav url={url} />
      {children}
    </>
  );
};

export default PhotoPageLayout;
